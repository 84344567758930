import React from 'react';

import { 
  TextField, 
  Box,  
  FormControlLabel, 
  InputAdornment,
  FormControl,
  FormLabel,
  RadioGroup,
  FormHelperText,
  Radio, 
} from '@mui/material';

import { 
  UseFormRegister, 
  FieldErrors, 
  Controller, 
  Control, 
  UseFormWatch,
} from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { SsrSchema } from '../../../utils/schemas/ssrSchema';


type SsrSanitizationAndExchangeProps = {
  register: UseFormRegister<SsrSchema>
  watch?: UseFormWatch<SsrSchema>;
  errors: FieldErrors<SsrSchema>;
  control: Control<SsrSchema>;
  selectedService: string | null;
}

export const SsrSanitizationAndExchange: React.FC<SsrSanitizationAndExchangeProps> = ({
  watch,
  errors,
  control,
  selectedService
}) => {
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2, justifyContent: 'start' }}>
      <Box style={{width: "100%", fontSize: "20px", marginTop: "10px", color: '#0F3354'}}>
        Water Quality
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: "10px", justifyContent: 'space-between', rowGap: "10px" }}>
        <Controller
          name="sanitizationAndExchanges.startSanitizationTime"
          control={control}
          render={({ field }) => (
            <TimePicker
              sx={{width: "400px"}}
              label="Beginning Time"
              value={field.value}
              onChange={(newValue) => field.onChange(newValue)}
              slotProps={{
                textField: {
                  error: !!errors.sanitizationAndExchanges?.startSanitizationTime,
                }
              }}
            />
          )}
        />
        
        <Controller
          name="sanitizationAndExchanges.endSanitizationTime"
          control={control}
          render={({ field }) => (
            <TimePicker
              sx={{width: "400px"}}
              label="Ending Time"
              value={field.value}
              onChange={(newValue) => field.onChange(newValue)}
              slotProps={{
                textField: {
                  error: !!errors.sanitizationAndExchanges?.endSanitizationTime,
                  helperText: errors.sanitizationAndExchanges?.endSanitizationTime?.message
                }
              }}
            />
          )}
        />
        <Controller
          name="sanitizationAndExchanges.beginningWaterQuality"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <NumericFormat
              sx={{width: 400}}
              fixedDecimalScale
              allowNegative={false}
              label="Beginning Water Quality"
              helperText={errors?.sanitizationAndExchanges?.beginningWaterQuality?.message}
              error={!!errors.sanitizationAndExchanges?.beginningWaterQuality}
              slotProps={{
                input: {
                  endAdornment: <InputAdornment position="end">MΩ cm c</InputAdornment>,
                },
              }}
              customInput={TextField}
              thousandSeparator=","
              decimalSeparator="."
              decimalScale={2}
              getInputRef={ref}
              {...rest}
              fullWidth
            />
          )}
        />
              <Controller
          name="sanitizationAndExchanges.endingWaterQuality"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <NumericFormat
              sx={{width: 400}}
              fixedDecimalScale
              allowNegative={false}
              label="Ending Water Quality"
              helperText={errors?.sanitizationAndExchanges?.endingWaterQuality?.message}
              error={!!errors.sanitizationAndExchanges?.endingWaterQuality}
              slotProps={{
                input: {
                  endAdornment: <InputAdornment position="end">MΩ cm c</InputAdornment>,
                },
              }}
              customInput={TextField}
              thousandSeparator=","
              decimalSeparator="."
              decimalScale={2}
              getInputRef={ref}
              {...rest}
              fullWidth
            />
          )}
        />

        <Controller
          name="sanitizationAndExchanges.beginningWaterQualityTemp"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <NumericFormat
              sx={{width: 400}}
              fixedDecimalScale
              allowNegative={false}
              label="Beginning Temp"
              helperText={errors?.sanitizationAndExchanges?.beginningWaterQualityTemp?.message}
              error={!!errors.sanitizationAndExchanges?.beginningWaterQualityTemp}
              slotProps={{
                input: {
                  endAdornment: <InputAdornment position="end">°C</InputAdornment>,
                },
              }}
              customInput={TextField}
              thousandSeparator=","
              decimalSeparator="."
              decimalScale={2}
              getInputRef={ref}
              {...rest}
            />
          )}
        />

        <Controller
          name="sanitizationAndExchanges.endingWaterQualityTemp"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <NumericFormat
              sx={{width: 400}}
              fixedDecimalScale
              allowNegative={false}
              label="Ending Temp"
              helperText={errors?.sanitizationAndExchanges?.endingWaterQualityTemp?.message}
              error={!!errors.sanitizationAndExchanges?.endingWaterQualityTemp}
              slotProps={{
                input: {
                  endAdornment: <InputAdornment position="end">°C</InputAdornment>,
                },
              }}
              customInput={TextField}
              thousandSeparator=","
              decimalSeparator="."
              decimalScale={2}
              getInputRef={ref}
              {...rest}
              fullWidth
            />
          )}
        />
      </Box>
      <Box sx={{display: 'flex', justifyContent: "space-between", flexWrap: "wrap", flexDirection: "column", rowGap: "10px"}}>
        <Controller
          name="sanitizationAndExchanges.lfModuleReadingsAccurate"
          control={control}
          render={({field}) => (
          <FormControl error={!!errors.ssrType?.ssrType}>
            <FormLabel>LF Module Readings Accurate</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={field.value}
              onChange={(e)=> field.onChange(e.target.value)}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
              <FormControlLabel value="N/A" control={<Radio />} label="N/A" />
            </RadioGroup>
            <FormHelperText>{errors.sanitizationAndExchanges?.lfModuleReadingsAccurate?.message}</FormHelperText>
          </FormControl>
          )}
        />
        <Controller
          name="sanitizationAndExchanges.upwCollected"
          control={control}
          render={({field}) => (
          <FormControl error={!!errors.sanitizationAndExchanges?.upwCollected}>
            <FormLabel>100mL UPW Collected</FormLabel>
            <RadioGroup
              row
              name="radio-buttons-group"
              value={field.value}
              onChange={(e)=> field.onChange(e.target.value)}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
              <FormControlLabel value="N/A" control={<Radio />} label="N/A" />
            </RadioGroup>
            <FormHelperText>{errors.sanitizationAndExchanges?.upwCollected?.message}</FormHelperText>
          </FormControl>
          )}
        />
        <Controller
          name="sanitizationAndExchanges.connectServiceTubing"
          control={control}
          render={({field}) => (
          <FormControl error={!!errors.sanitizationAndExchanges?.connectServiceTubing}>
            <FormLabel>Connect Service Tubing</FormLabel>
            <RadioGroup
              row
              name="radio-buttons-group"
              value={field.value}
              onChange={(e)=> field.onChange(e.target.value)}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
              <FormControlLabel value="N/A" control={<Radio />} label="N/A" />
            </RadioGroup>
            <FormHelperText>{errors.sanitizationAndExchanges?.connectServiceTubing?.message}</FormHelperText>
          </FormControl>
          )}
        />
        <Controller
          name="sanitizationAndExchanges.flowRateSensorAdjusted"
          control={control}
          render={({field}) => (
          <FormControl error={!!errors.sanitizationAndExchanges?.flowRateSensorAdjusted}>
            <FormLabel>Flow Rate Sensor Adjusted</FormLabel>
            <RadioGroup
              row
              name="radio-buttons-group"
              value={field.value}
              onChange={(e)=> field.onChange(e.target.value)}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
              <FormControlLabel value="N/A" control={<Radio />} label="N/A" />
            </RadioGroup>
            <FormHelperText>{errors.sanitizationAndExchanges?.flowRateSensorAdjusted?.message}</FormHelperText>
          </FormControl>
          )}
        />
        <Controller
          name="sanitizationAndExchanges.exchangesCompleted"
          control={control}
          render={({field}) => (
          <FormControl error={!!errors.sanitizationAndExchanges?.exchangesCompleted}>
            <FormLabel>Exchanges Completed</FormLabel>
            <RadioGroup
              row
              name="radio-buttons-group"
              value={field.value}
              onChange={(e)=> field.onChange(e.target.value)}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
              <FormControlLabel value="N/A" control={<Radio />} label="N/A" />
            </RadioGroup>
            <FormHelperText>{errors.sanitizationAndExchanges?.exchangesCompleted?.message}</FormHelperText>
          </FormControl>
          )}
        />
      </Box>
    </Box>
  );
}
