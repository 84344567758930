
import { 
  FieldErrors,
  Controller,
  Control,
  UseFormWatch,
} from 'react-hook-form';

import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from '@mui/material';

import { useEffect } from 'react';

import { SsrSchema } from '../../../utils/schemas/ssrSchema';
import useGetSalesOrderData from '../../../hooks/salesOrder/useGetSalesOrder'

type SsrServiceFormProps = {
  errors: FieldErrors<SsrSchema>;
  control: Control<SsrSchema>;
  watch: UseFormWatch<SsrSchema> 
}

const SsrServiceForm: React.FC<SsrServiceFormProps> = ({errors, control, watch}) => {

  const {fetchSalesOrderData } = useGetSalesOrderData();
  const selectedSsrType = !!watch ?  watch('ssrType.ssrType'): null

  useEffect(() => {
    if(selectedSsrType !== ''){
      fetchSalesOrderData()
    }
  }, [selectedSsrType, fetchSalesOrderData,])

  return(
  <>
    <div>
      <Controller
        name="ssrType.ssrType"
        control={control}
        render={({field}) => (
        <FormControl error={!!errors.ssrType?.ssrType}>
          <FormLabel id="demo-radio-buttons-group-label">Select SSR Type</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue= "ssrType.ssrType"
            name="radio-buttons-group"
            value={field.value}
            onChange={(e)=> field.onChange(e.target.value)}
          >
            <FormControlLabel value="service" control={<Radio />} label="Service" />
            <FormControlLabel value="installation" control={<Radio />} label="Installation" />
            <FormControlLabel value="relocation" control={<Radio />} label="Relocation" />
            <FormControlLabel value="warranty" control={<Radio />} label="Warranty" />
            <FormControlLabel value="emergencyEvaluation" control={<Radio />} label="Emergency-Evaluation" />
            <FormControlLabel value="emergencyEvaluationRepair" control={<Radio />} label="Emergency-Evaluation & Repair" />
          </RadioGroup>
          <FormHelperText>{errors.ssrType?.ssrType?.message}</FormHelperText>
        </FormControl>
        )}
      />
    </div>
  </>
  )

}

export default SsrServiceForm