import React from 'react';

import { 
  TextField, 
  Box,  
  FormControlLabel, 
  Switch, 
  InputAdornment, 
} from '@mui/material';
import { 
  UseFormRegister, 
  FieldErrors, 
  Controller, 
  Control, 
  UseFormWatch,
} from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { NumericFormat, PatternFormat } from 'react-number-format';

import { SsrSchema } from '../../../utils/schemas/ssrSchema';
import { TScope } from '../../../utils/types/scopesTypes';

type PersonalInfoTabProps = {
  register: UseFormRegister<SsrSchema>;
  watch?: UseFormWatch<SsrSchema>;
  scopesData: TScope[] | null;
  isLoadingScopes: boolean;
  errors: FieldErrors<SsrSchema>;
  control: Control<SsrSchema>;
  scopeEntry: string;
  setScopeEntry: (e: any) => void
  selectedService: string | null;
}

export const SsrGeneralForm: React.FC<PersonalInfoTabProps> = ({
  register,
  watch,
  scopesData,
  isLoadingScopes,
  errors,
  control,
  scopeEntry,
  setScopeEntry,
  selectedService,

}) => {

  const isValidMonthValue = (value: string) => {
    const num = parseFloat(value);
    return Number.isInteger(num) || num % 1 === 0.5;
  };
  
  return (
    scopesData !== null && !isLoadingScopes ? (
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2, justifyContent: 'space-between' }}>
      <Box style={{width: "100%", fontSize: "20px", marginTop: "10px", color: '#0F3354'}}>
        General
      </Box>
      
      <TextField
        sx={{width: 400}}
        label="Contact"
        type="contact"
        {...register('general.contact')}
        error={!!errors.general?.contact}
        helperText={errors.general?.contact?.message}
        fullWidth
      />

      <Controller
        name="general.phone"
        control={control}
        render={({ field: { ref, ...rest } }) =>(
          <PatternFormat 
            format="(###) ### - #### ext: #####"
            label="Phone"
            sx={{width: 400}} 
            customInput={TextField}
            getInputRef={ref}
            error={!!errors.general?.phone}
            helperText={errors.general?.phone?.message}
            {...rest}
          />
        )}
      />

      {selectedService === "installation" ? (
      <Controller
        name="general.comissionDate"
        control={control}
        render={({ field }) => (
          <DatePicker
            label="Commission Date"
            value={field.value}
            onChange={(newValue) => field.onChange(newValue)}
            slotProps={{
              textField: {
                error: !!errors.general?.comissionDate,
                helperText: errors.general?.comissionDate?.message
              }
            }}
          />
        )}
      />
      ) : (<></>)}

      <TextField
        sx={{width: 400}}
        label="Validated Ports"
        {...register('general.validatedPorts')}
        error={!!errors.general?.validatedPorts}
        helperText={errors.general?.validatedPorts?.message}
        fullWidth
      />
      <Controller
        name="general.scope"
        control={control}
        render={({field}) => (
            <TextField
              fullWidth
              {...field}
              value={scopeEntry}
              onChange={(e) => {
                field.onChange(e);
                setScopeEntry(e.target.value);
              }}
              label="scope"
              error={!!errors.general?.scope}
              helperText={errors.general?.scope?.message}
            />
        )}
      />
      <Box style={{width: "100%", fontSize: '20px', color: '#0F3354', marginTop: "10px"}}>
        Volume Data
      </Box>

      <Controller
        name="general.currentVolume"
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <NumericFormat
            sx={{width: 400}}
            fixedDecimalScale
            allowNegative={false}
            label="Current Volume"
            helperText={errors?.general?.currentVolume?.message}
            error={!!errors.general?.currentVolume}
            slotProps={{
              input: {
                endAdornment: <InputAdornment position="end">L</InputAdornment>,
              },
            }}
            customInput={TextField}
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={2}
            getInputRef={ref}
            {...rest}
            fullWidth
          />
        )}
      />

      <Controller
        name="general.servicedVolume"
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <NumericFormat
            sx={{width: 400}}
            fixedDecimalScale
            allowNegative={false}
            label="Serviced Volume"
            helperText={errors?.general?.servicedVolume?.message}
            error={!!errors.general?.servicedVolume}
            slotProps={{
              input: {
                endAdornment: <InputAdornment position="end">L</InputAdornment>,
              },
            }}
            customInput={TextField}
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={2}
            getInputRef={ref}
            {...rest}
            fullWidth
          />
        )}
      />

      <Controller
        name="general.totalVolume"
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <NumericFormat
            sx={{width: 400}}
            fixedDecimalScale
            allowNegative={false}
            label="Total Volume"
            helperText={errors?.general?.totalVolume?.message}
            error={!!errors.general?.totalVolume}
            slotProps={{
              input: {
                endAdornment: <InputAdornment position="end">L</InputAdornment>,
              },
            }}
            customInput={TextField}
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={2}
            getInputRef={ref}
            {...rest}
            fullWidth
          />
        )}
      />

      <Controller
        name="general.totalMonths"
        control={control}
          render={({ field: { onChange, value } }) => (
          <NumericFormat
            value={value}
            onValueChange={(values) => {
              onChange(values.value);
            }}
            sx={{width: 400}}
            fixedDecimalScale
            allowNegative={false}
            label="Total Months"
            helperText={errors?.general?.totalMonths?.message}
            error={!!errors.general?.totalMonths}
            slotProps={{
              input: {
                endAdornment: <InputAdornment position="end">Months</InputAdornment>,
              },
            }}
            customInput={TextField}
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={1}
            isAllowed={(values) => {
              const { value } = values;
              if (value === '') return true;
              return isValidMonthValue(value);
            }}
            fullWidth
          />
        )}
      />

      <Controller
        name="general.globalReset"
        control={control}
        render={({field}) => (
          <FormControlLabel
            sx={{display: 'flex', alignContent: 'center', justifyContent: 'flex-start', width: "400px"}}
            control={
              <Switch
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                color="primary"
              />
            }
            label="Global Reset"
          />
        )}
      />
    </Box>
    ) : 
    <></>
  );
};