import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import ClientLabs from './ClientLabs';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}


function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ flexGrow: 1, width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ display: "flex" }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box 
      component="main" 
      sx={{ 
        width:`calc(100% - 240px)`, 
        display: 'flex', 
        flexGrow: 1, p: 4, 
        marginTop: "35px", 
        backgroundColor: "white", 
        marginLeft: "120px" 
      }}
    >
    <Box sx={{ width: '100%', flexGrow: 1, p: 4 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Lab" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <Box sx={{ display:"flex", width: '100%', flexGrow: 1}}>      
        <CustomTabPanel value={value} index={0}>
          <ClientLabs/>
        </CustomTabPanel>
      </Box>
    </Box>
  </Box>
)
}
