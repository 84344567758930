import { useCallback, useMemo, useState, useRef } from "react";

import { headers } from "../authHeader";
import { TSalesOrder } from "../../utils/types/salesOrderTypes";

const PAGE_SIZE = 10;

const useGetSalesOrderData = () => {
  const [isLoadingSalesOrder, setIsLoadingSalesOrder] = useState<boolean>(false);
  const [errorSalesOrder, setErrorSalesOrder] = useState<any>(null);
  const [salesOrderState, setSalesOrderState] = useState<Array<TSalesOrder> | null>(null);
  const [salesOrderTotalPages, setSalesOrderTotalPages] = useState(0);
  const [salesOrderTotalEntries, setSalesOrderTotalEntries] = useState<number>(0);
  const currentFetchId = useRef(0)

  const fetchSalesOrderData = useCallback(
    async (allSalesOrderData = false, pageNumber?: number | null, searchTerm?: string | null, pageSize?:number | null, orderBy?: string | null, ascending?: boolean | null, labId?: string ) => {
      const fetchId = ++currentFetchId.current
      let baseUrl = `https://pls-api-appservice.azurewebsites.net/api/TlSaleOrder`;
      let queryParams = `?PageSize=${!!pageSize ? pageSize : 10}&PageNumber=${!!pageNumber ? pageNumber : 1}&OrderBy=${!!orderBy ? orderBy : "soId"}&Ascending=${!!ascending ? ascending: true}${!!labId ? "&labId="+labId : null}`
      let urlWithQueryParams = baseUrl + queryParams

      setIsLoadingSalesOrder(true);
      setErrorSalesOrder(null);

      console.log(' is allSalesOrderData coming back true?', allSalesOrderData ? urlWithQueryParams : baseUrl)
      try {
        const response = await fetch(allSalesOrderData ? urlWithQueryParams: baseUrl, {
          headers,
          method: "GET",
        });

        if (!response.ok) {
          throw new Error(
            "Server returned error code: " +
              response.status +
              " " +
              (await response.text())
          );
        }

        const responsejson = await response.json();
        if (fetchId === currentFetchId.current) {
          setSalesOrderState(responsejson.salesOrders);
          setSalesOrderTotalEntries(responsejson.metaData.totalEntries);
          setSalesOrderTotalPages(Math.ceil(salesOrderTotalEntries / PAGE_SIZE));
          setIsLoadingSalesOrder(false);
        } else {
          console.log("Fetch Operation Expired")
        }
      } catch (error: any) {
        setErrorSalesOrder(error);
        setIsLoadingSalesOrder(false);
      }
    },
    [salesOrderTotalEntries]
  );

  const salesOrderData = useMemo(() => {
    if (salesOrderState == null) {
      return null;
    }
    return salesOrderState.map(({
      sorderId, 
      userId, 
      clientId, 
      labId, 
      quoteId, 
      taxId, 
      clientPo, 
      via,
      total,
      totaltax,
      note,
      internalNote,
      status,
      cancelNote,
      soEmail,
      soShipaddress,
      soTax,
      noPo,
      soReport,
      psType,
      poId,
      lastShipDate,
      dueDate,
      createdAt,
      updatedAt,
      isEva  
    }) => (
    {
      sorderId, 
      userId, 
      clientId, 
      labId, 
      quoteId, 
      taxId, 
      clientPo, 
      via,
      total,
      totaltax,
      note,
      internalNote,
      status,
      cancelNote,
      soEmail,
      soShipaddress,
      soTax,
      noPo,
      soReport,
      psType,
      poId,
      lastShipDate,
      dueDate,
      createdAt,
      updatedAt,
      isEva,

    }
    ));
  }, [salesOrderState]);

  return { salesOrderData, salesOrderTotalPages, salesOrderTotalEntries, isLoadingSalesOrder, errorSalesOrder, fetchSalesOrderData };
}

export default useGetSalesOrderData