import { z } from 'zod';
import dayjs, { Dayjs } from 'dayjs';

export const ssrFormSchema = z.object({
  ssrType: z.object({
    ssrType: z.string().min(1, 'Please select an SSR type to continue')
  }),
  general: z.object({
    contact: z.string().min(2, 'Contact must be at least 2 characters'),
    phone: z.string().min(10, 'Valid Phone number required'),
    comissionDate: z.custom<Dayjs>((val) => val instanceof dayjs),
    validatedPorts: z.string().min(1, 'Validated Ports are required to complete the form'),
    warrantyService: z.boolean(),
    scope: z.number().min(1, {message: 'Scope is required to complete the form'}).or(z.string().min(1, {message: 'Scope is required to complete the form'})),
    priorDate: z.string(),
    priorVolume: z.string(),
    currentVolume: z.string().min(1, {message: 'Current Volume is required to complete the form'}),
    servicedVolume: z.string().min(1, {message: 'Serviced Volume is required to complete the form'}),
    totalVolume: z.string().min(1, {message: 'Total Volume is required to complete the form'}),
    totalMonths: z.string().min(1, {message: 'Total Months is required to complete the form'}),
    globalReset: z.boolean(),
  }),
  initialReadings: z.object({
    other: z.string()
  }),
  sanitizationAndExchanges: z.object({
    beginningWaterQuality: z.string().min(1, 'Water Quality is a required field'),
    beginningWaterQualityTemp: z.string().min(1, 'Water Quality temperature is a required field'),
    startSanitizationTime: z.custom<Dayjs>((val) => val instanceof dayjs),
    endingWaterQuality: z.string().min(1, 'Ending water quality is a required field'),
    endingWaterQualityTemp: z.string().min(1, 'Ending water temperature is a required field'),
    endSanitizationTime: z.custom<Dayjs>((val) => val instanceof dayjs),
    feedWaterQuality: z.string().min(1, 'Feed Water Quality is a required field'),
    lfModuleReadingsAccurate: z.string().min(1, 'Please select a Reading Accuracy option'),
    upwCollected: z.string().min(1, "Please select if UPW was collected"),
    connectServiceTubing: z.string().min(1, "Please select if Service tubing was connected"),
    flowRateSensorAdjusted: z.string().min(1, "Please select an option verify Volumetric Flow"),
    exchangesCompleted: z.string().min(1, "Please verify that exchanges are completed"),
  }),
  verification: z.object({
    stuff: z.string()
  }),
});

export type SsrSchema = z.infer<typeof ssrFormSchema>;