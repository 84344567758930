import { Box } from '@mui/material';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const SsrTabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{margin: "0 50px"}}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Box sx={{ display: {lg: 'none'}, color: '#0F3354', fontSize:'28px'}}>
            System Service Report
          </Box>
          {children}
        </Box>
      )}
    </div>
  );
};
