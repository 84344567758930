import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Card,
  CardContent,
  Tabs,
  Tab,
  Button,
} from '@mui/material';

import dayjs from 'dayjs';

import { SsrSchema, ssrFormSchema } from '../../utils/schemas/ssrSchema';
import { SsrTabPanel } from './SsrTabPanel';
import { SsrGeneralForm } from './ssrFormSubComponents/SsrGeneralForm';
import SsrTypeForm from './ssrFormSubComponents/SsrServiceForm';
import Placeholder from '../placeholder/Placeholder'
import useGetScopesData from '../../hooks/scopes/useGetScopes';
import { SsrSanitizationAndExchange } from './ssrFormSubComponents/SsrSanitizationAndExchange';

type ScopeKey = 'service' | 'installation' | 'relocation' | 'warranty' | 'emergencyEvaluation' | 'emergencyEvaluationRepair';

type SsrTabGroup = keyof SsrSchema;
type TabInfo = {
  tabGroup: SsrTabGroup;
  name: string;
}


const SsrPage = () => {
  const {scopesData, isLoadingScopes, fetchScopesData} = useGetScopesData();
  const [currentTab, setCurrentTab] = useState(0);
  const [startTime, setStartTime] = useState<number>();
  const [endTime, setEndTime] = useState<number>();
  const [scopeEntry, setScopeEntry] = useState<string>("default scope value");

  const {
    register,
    handleSubmit,
    control,
    watch,
    trigger,
    formState: { errors },
  } = useForm<SsrSchema>({
    mode: "onChange",
    delayError: 500, 
    resolver: zodResolver(ssrFormSchema),
    defaultValues: {
      ssrType: {
        ssrType: '',
      },
      general: {
        contact: '',
        phone: '',
        comissionDate: dayjs(new Date()),
        validatedPorts: '',
        warrantyService: false,
        scope: 'default value',
        priorDate: '',
        priorVolume: '',
        currentVolume: '',
        servicedVolume: '',
        totalVolume: '',
        totalMonths: '',
        globalReset: false,
      },
      sanitizationAndExchanges: {
        beginningWaterQuality: '',
        beginningWaterQualityTemp: '',
        startSanitizationTime: dayjs(new Date()),
        endingWaterQuality: '',
        endingWaterQualityTemp: '',
        endSanitizationTime: dayjs(new Date()),
        upwCollected: '',
        connectServiceTubing: '',
        flowRateSensorAdjusted: '',
      },
    },
  });

  const ssrTabs: TabInfo[] = [
    {tabGroup: 'ssrType', name: "SSR Type"},
    {tabGroup: 'general', name: "General & Volume Data"},
    {tabGroup: 'sanitizationAndExchanges', name: "Sanitization and Exchanges"},
    {tabGroup: 'initialReadings', name: "Initial Readings"},
    {tabGroup: 'verification', name: "Verification"},
  ];
  
  const selectedService = !! watch ? watch('ssrType.ssrType') : ''

  const onSubmit = (data: SsrSchema) => {
    //Add data that should be hidden from the user, such as client or lab. 
    setEndTime(Date.now())

    console.log(
      'Form submitted:', 
      {
        ...data,
        startTime,
        endTime
        //invoiceNumber,
        //poNumber
        //client.id
        //client.lab.labId,
      });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    trigger(ssrTabs[currentTab].tabGroup as keyof SsrSchema)
    if(selectedService.length < 1){
      return
    } else {
      setCurrentTab(newValue);
    }
  };

  const handleNext = () => {
    trigger(ssrTabs[currentTab].tabGroup as keyof SsrSchema)
    if(selectedService.length < 1){
      return
    } else {
      setCurrentTab(prev => {
        const value = Math.min(4, prev + 1)
        return value
      })
    }
  };

  useEffect(() => {
    setStartTime(Date.now())
  }, []);

  useEffect(() => {
    const generalErrors = []
    for(let key in errors.general){ generalErrors.push(key)}
  }, [errors]);

  useEffect(() => {
    fetchScopesData()
  }, [fetchScopesData]);

  useEffect(() => {
    const scopes = {
      service: "Sanitization service. See below for consumable exchanges. Verify &amp; record operational parameters.",
      installation: "Install and commission water system. See below for consumable exchange information. Verify &amp; record operational parameters.",
      relocation: "Decommission, relocation, installation, and commission of water system. Verify &amp; record operational parameters.",
      warranty: "Warranty evaluation of [issue]. “Like for Like” replacement of [part]. Verify &amp; record operational parameters.",
      emergencyEvaluation: "Emergency Evaluation of [issue]. Verify &amp; record operational parameters.",
      emergencyEvaluationRepair: "Emergency Evaluation of [issue]. “Like for Like” replacement of art]. Verify &amp; record operational parameters."
    }

    const ssrService = selectedService;
    if (ssrService && ssrService in scopes) {
      const initialScope = scopes[ssrService as ScopeKey];
      setScopeEntry(initialScope);
    }
  }, [setScopeEntry, selectedService]);


  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "35px" }}>
      <Box sx={{ maxWidth: 1200, mx: 'auto', p: 2 }}>
      <Box sx={{ 
        display: {
          xs: 'none', 
          sm: 'none', 
          md: 'none', 
          lg:'block'
        }, 
        fontSize:'36px', 
        backgroundColor: "white", 
        color:"#0F3354", 
        padding: "15px 10px",
        marginTop: "10px",
        marginBottom: "10px",
      }}>
        System Service Report
      </Box>
        <Card>
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ 
                display: 'flex', 
                borderBottom: 1, 
                borderColor: 'divider', 
                height: "60vh" 
                }}
              >
                <Tabs
                  indicatorColor="secondary"
                  textColor= "secondary"
                  orientation="vertical"
                  variant="scrollable"
                  value={currentTab}
                  onChange={handleTabChange}
                  aria-label="form tabs"
                  sx={{ 
                    marginTop: 0, 
                    flexGrow: 1, 
                    borderRight: 1, 
                    borderColor: 'divider', 
                    minWidth: "170px", 
                    maxWidth: "170px" 
                  }}
                >
                  {
                    ssrTabs.map((tabInfo: TabInfo, index: number) => {
                      const getErrorCount = () => {
                        if (!tabInfo.tabGroup) return '';
                        
                        const errorsForGroup = errors[tabInfo.tabGroup as keyof SsrSchema];
                        if (errorsForGroup && typeof errorsForGroup === 'object') {
                          return Object.keys(errorsForGroup).length;
                        }
                        return '';
                      };

                      return(
                        <Tab
                          key={window.crypto.randomUUID()}
                          sx={{ textAlign: "start", justifyContent: "flex-start"}}
                          icon={
                            <div style={
                              !!errors[tabInfo.tabGroup as keyof SsrSchema] ? {
                                background: '#d32f2f',
                                color: "white",
                                borderRadius: "50%",
                                padding: "10px",
                                width: "24px",
                                height: "24px",
                                display: 'flex',
                                alignItems: "center",
                                justifyContent: "center"
                              }
                              : 
                              {}
                            }>
                              <div>
                                  {getErrorCount()}
                              </div>
                            </div>
                          } 
                          iconPosition="end" 
                          label = {tabInfo.name}
                        />
                      )
                    })
                  }
                </Tabs>
                <Box style={{overflow: 'auto', width: "100%"}}>
                        <SsrTabPanel value={currentTab} index={0}>
                          <SsrTypeForm control={control} errors={errors} watch={watch}/>
                        </SsrTabPanel>
                        <SsrTabPanel value={currentTab} index={1}>
                          <SsrGeneralForm 
                            control={control} 
                            errors={errors} 
                            register={register} 
                            scopesData={scopesData} 
                            watch={watch} 
                            isLoadingScopes={isLoadingScopes} 
                            scopeEntry={scopeEntry} 
                            setScopeEntry={setScopeEntry} 
                            selectedService={selectedService} 
                          />
                        </SsrTabPanel>
                        <SsrTabPanel value={currentTab} index={2}>
                          <Placeholder/>
                        </SsrTabPanel>
                        <SsrTabPanel value={currentTab} index={3}>
                          <SsrSanitizationAndExchange
                            control={control}
                            errors={errors}
                            register={register}
                            watch={watch}
                            selectedService={selectedService}
                          />
                        </SsrTabPanel>

                        <SsrTabPanel value={currentTab} index={4}>
                          <Placeholder/>
                        </SsrTabPanel>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                <Button
                  variant="outlined"
                  onClick={() => setCurrentTab(prev => Math.max(0, prev - 1))}
                  disabled={currentTab === 0}
                >
                  Previous
                </Button>
                
                {currentTab === ssrTabs.length-1 ? (
                  <Button
                    variant="contained"
                    type="submit"
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                )}
              </Box>
            </form>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default SsrPage;